export function trans_time(str){
  var resDate = ''
  if(str != '' && str != undefined){
    var d = new Date(str)
    resDate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
  }
  return resDate
}


export function replaceAll(str, arg1, arg2){
  let str_list = str.split(arg1)
  return str_list.join(arg2)
}
