import { ElMessage, ElMessageBox } from 'element-plus'

// 提供的公用确认方法
export function showConfirmMessageBox (message, safe_html=false) {
  return ElMessageBox.confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    dangerouslyUseHTMLString: safe_html,
    type: 'warning',
    // center: true
  })
}

// 提供的公用提示确认方法
export function showMessageBox (message) {
  return ElMessageBox.alert(message, '提示', {
    confirmButtonText: '确定',
    // center: true,
  })
}

// 公用的提示方法
export function showMessage (message, state) {
  ElMessage({
    showClose: true,
    message: message,
    type: state
  })
}

// 提供的公用左对齐提示确认方法
export function showLeftMessageBox (message) {
  return ElMessageBox.alert(message, '提示', {
    confirmButtonText: '确定',
    dangerouslyUseHTMLString:true
  })
}
