/**
 * 后台角色API
 */
import { fetch, post, del, postForm } from 'api/base/axios';

const URL = {
  updateUserLoginState: '/user/login/update/state/?not_active=true',
  getUserStateUrl: '/user/login/state/',
  checkUserNeedLoginUrl: '/user/login/need/login/',
  loginUserUrl: '/user/login/',
  logOutUserUrl: '/user/login/logout/',
  addCellPhoneUrl: '/user/login/add/phone/',
  forgetPwdUrl:  '/user/login/forget/pwd/',
  updatePwdUrl:  '/user/login/update/pwd/',
  userUnBindUrl: '/user/login/unbind/third/',
  sendUserEmailUrl: '/user/login/send/email/',
  checkUserEmailUrl: '/user/email/confirm/',
  oauthAuthLoginUrl: '/user/login/oauth_auto_login/',
  getThirdRedirectUriUrl: '/user/login/third_redirect_uri/',
  bindMobileThirdAccountUrl: '/user/social-login/mobile/',
  getDomainSiteUrl: '/user/login/domain_site/',
};

export function oauthAuthLogin (oauth_token) {
  return post(URL.oauthAuthLoginUrl, {
    'oauth_token': oauth_token
  });
}

export function GetUserState (data={}) {
  return fetch(URL.getUserStateUrl, data);
}

export function updateUserLoginState () {
  return fetch(URL.updateUserLoginState);
}

export function mustUserLogin () {
  return fetch(URL.checkUserNeedLoginUrl);
}

export function LoginUser (form) {
  return post(URL.loginUserUrl, form);
}

export function LogOutUser () {
  return fetch(URL.logOutUserUrl);
}

/**
 * 第三方登录
 */
export function ThirdPartyLogin (type, target=null, platform='pc') {
  let next = target ? target : location.pathname;

  let url_prefix = `${document.location.protocol}//${document.location.host}`;

  let url = url_prefix + "/api/user/login/third/" + type + "/login/?target=" + next + '&platform=' + platform;
  if(platform == 'pc'){
    openWindow(url);
  }
  else {
    location.href = url
  }

}

function openWindow(url) {
  window.open (url, "newwindow", "height=500, width=500, top=100," +
                 "left=200,toolbar=no, menubar=no, scrollbars=no, resizable=no,  status=no");
}

export function AddCellPhone (form) {
  return post(URL.addCellPhoneUrl, form);
}

export function ForgetPWD (form) {
  return post(URL.forgetPwdUrl, form);
}

export function UpdatePWD (form) {
  return post(URL.updatePwdUrl, form);
}

export function userBindThirdParty (user_id, third_type, platform='pc') {
  let url = "/api/user/login/third/" + third_type + "/bind/?user_id=" + user_id + "&platform=" + platform;
  openWindow(url);
}

export function userUnBindThirdParty (user_id, third_type) {
  return post(URL.userUnBindUrl, {"user_id": user_id, "third_type": third_type});
}

export function sendUserEmail() {
  return fetch(URL.sendUserEmailUrl);
}

export function checkUserEmail(data) {
  return post(URL.checkUserEmailUrl, data);
}

export function getThirdRedirectUri(third_type, user_id){
  return fetch(URL.getThirdRedirectUriUrl+ third_type + "/bind/?user_id=" + user_id)
}

export function bindMobileThirdAccount(third_type, callback_type, data){
  return fetch(URL.bindMobileThirdAccountUrl + third_type + '/' + callback_type + '/', data)
}

export function getDomainSite(){
  return fetch(URL.getDomainSiteUrl)
}
