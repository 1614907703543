import { createRouter, createWebHistory } from 'vue-router'
import userRoutes from './user.js'
import configRoutes from './config.js'
import { mustUserLogin } from 'api/user/auth/login';

const NotFoundComponent = () => import('views_f/NotFoundComponent')
const Login = () => import('views_f/Login.vue')
const Frame = () => import('views_f/Frame.vue')
const Home = () => import('views_f/Home.vue')
const CourseList = () => import('views_f/course/List.vue')
const CourseDetail = () => import('views_f/course/Detail.vue')
const CourseTeaching = () => import('views_f/course/Teaching.vue')
const CourseTeachingContent = () => import('views_f/course/TeachingContent.vue')
const CourseExercise = () => import('views_f/course/Exercise.vue')
const CustomPage = () => import('views_f/course/CustomPage.vue')
const Training = () => import('views_f/Training.vue')
const DiyExperiment = () => import('views_f/experiment/Diy.vue')
const DiyDetail = () => import('views_f/experiment/DiyDetail.vue')
const ExptDetail = () => import('views_f/experiment/ExptDetail.vue')
const ExptView = () => import('views_f/experiment/ExptView.vue')
const Center = () => import('views_f/center/Center.vue')
const CenterAccount = () => import('views_f/center/Account.vue')
const CenterCourse = () => import('views_f/center/Course.vue')
const CenterExperiment = () => import('views_f/center/Experiment.vue')
const CenterCertificate = () => import('views_f/center/Certificate.vue')
const CenterCode = () => import('views_f/center/Code.vue')
const CenterCard = () => import('views_f/center/Card.vue')
const CenterCollect = () => import('views_f/center/Collect.vue')
const CenterOrder = () => import('views_f/center/Order.vue')
const CenterTicket = () => import('views_f/center/Ticket.vue')
const CenterTicketApply = () => import('views_f/center/TicketApply.vue')

const Cart = () => import('views_f/settlement/Cart.vue')
const OrderConfirm = () => import('views_f/settlement/order/Confirm.vue')
const OrderPay = () => import('views_f/settlement/order/Pay.vue')
const OrderResult = () => import('views_f/settlement/order/Result.vue')
const Coupon = () => import('views_f/settlement/Coupon.vue')

const AboutUs = () => import('views_f/about/AboutUs.vue')
const CertificateQuery = () => import('views_f/about/CertificateQuery.vue')
const EmailActiveResult = () => import('views_f/EmailActiveResult.vue')

const Agreement = () => import('views_f/Agreement.vue')
const DynamicPage = () => import('views_f/DynamicPage.vue')
// const Notice = () => import('views_f/Notice.vue')

const ZoneDetail = () => import('views_f/zone/ZoneDetail.vue')

const baseRoutes = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      name: '登录注册'
    }
  },
  {
    path: '/',
    name: 'Frame',
    component: Frame,
    children: [{
      path: 'home',
      name: 'Home',
      component: Home,
      meta: ['首页']
    },{
      path: 'course/list',
      name: 'CourseList',
      component: CourseList,
      meta:['全部课程', '课程列表']
    },{
      path: 'course/search',
      name: 'CourseSearch',
      component: CourseList,
      meta:['全部课程', '课程列表']
    },{
      path: 'course/detail/:uuid',
      name: 'CourseDetail',
      component: CourseDetail,
      meta:['全部课程', '课程详情']
    }, {
      path: 'zone/:zone_domain',
      name: 'ZoneDetail',
      component: ZoneDetail,
      meta: {
        name: '企业专区',
      }
    }, {
      path: 'training',
      name: 'Training',
      component: Training,
      meta: {
        name: '企业内训'
      }
    },{
      path: 'cart',
      name: 'Cart',
      component: Cart,
      meta: {
        name: '购物车',
        login: true
      }
    },{
      path: 'order/confirm/:confirm_key',
      name: 'OrderConfirm',
      component: OrderConfirm,
      meta: {
        name: '订单确认',
        login: true
      }
    },{
      path: 'order/pay/:method?/:order_uuid',
      name: 'OrderPay',
      component: OrderPay,
      meta: {
        name: '订单支付',
        login: true
      }
    },{
      path: 'order/result/:method?/:order_uuid',
      name: 'OrderResult',
      component: OrderResult,
      meta: {
        name: '订单完成',
        login: true
      }
    },{
      path: 'coupons/issue/:strategy_code?',
      name: 'CouponsIssue',
      component: Coupon,
      meta:['链接领取优惠券']
    },{
      path: 'aboutus',
      name: 'AboutUs',
      component: AboutUs,
      meta: {
        name: '了解我们'
      }
    },{
      path: 'certificatequery',
      name: 'CertificateQuery',
      component: CertificateQuery,
      meta: {
        name: '证书查询'
      }
    },{
      path: 'email/result/:user_id/:captcha',
      name: 'EmailActiveResult',
      component: EmailActiveResult,
      meta:['邮件激活']
    },{
      path: 'agreement',
      name: 'Agreement',
      component: Agreement,
      meta: {
        name: '注册协议'
      }
    },{
      path: 'page/:page_url+',
      name: 'DynamicPage',
      component: DynamicPage,
    },{
      path: 'center',
      redirect: '/center/account',
      name: 'Center',
      login: true,
      component: Center,
      children: [{
        path: 'account',
        name: 'CenterAccount',
        component: CenterAccount,
        meta: {
          name: '账号资料',
          login: true
        },
      },{
        path: 'course',
        name: 'CenterCourse',
        component: CenterCourse,
        meta: {
          name: '我的课程',
          login: true
        },
      },{
        path: 'certificate',
        name: 'CenterCertificate',
        component: CenterCertificate,
        meta: {
          name: '我的证书',
          login: true
        },
      },{
        path: 'experiment',
        name: 'CenterExperiment',
        component: CenterExperiment,
        meta: {
          name: '我的实验',
          login: true
        },
      },{
        path: 'code',
        name: 'CenterCode',
        component: CenterCode,
        meta: {
          name: '我的授权码',
          login: true
        },
      },{
        path: 'card/:tabName?',
        name: 'CenterCard',
        component: CenterCard,
        meta: {
          name: '我的卡包',
          login: true
        },
      },{
        path: 'collect',
        name: 'CenterCollect',
        component: CenterCollect,
        meta: {
          name: '我的收藏',
          login: true
        },
      },{
        path: 'order',
        name: 'CenterOrder',
        login: true,
        component: CenterOrder,
        meta: {
          name: '我的订单',
          login: true
        },
      },{
        path: 'ticket',
        name: 'CenterTicket',
        component: CenterTicket,
        meta: {
          name: '我的开票',
          login: true
        },
      },{
        path: 'ticket/apply',
        name: 'CenterTicketApply',
        component: CenterTicketApply,
        meta: {
          name: '开票申请',
          login: true
        },
      }]
    },{
      path: '/:catchAll(.*)',
      name: '404',
      component: NotFoundComponent
    },{
      path: '/error/:code',
      name: 'NotFoundComponent',
      component: NotFoundComponent
    },{
      path: '/custom/:nav_url+',
      name: 'CustomPage',
      component: CustomPage,
      meta:['自定义页面']
    },{
      path: 'diy/experiment',
      name: 'DiyExperiment',
      login: true,
      component: DiyExperiment,
      meta: {
        name: '开放实验室',
        login: true
      },
    },{
      path: 'expt/view/:course_uuid',
      name: 'ExptView',
      login: true,
      component: ExptView,
      meta: {
        name: '课程实验预览',
        login: true
      },
    }]
  },{
    path: '/course/detail/:type/:cur_uuid/:uuid',
    name: 'CourseTeaching',
    login: true,
    component: CourseTeaching,
    // meta:['全部课程', '课程详情', '课程教学']
    meta: {
      name: '课程教学',
      login: true
    },
  },{
    path: '/:type/detail/:uuid',
    name: 'ExptDetail',
    login: true,
    component: ExptDetail,
    meta: {
      name: '课程实验',
      login: true
    },
  },{
    path: '/diy/detail/:uuid',
    name: 'DiyDetail',
    login: true,
    component: DiyDetail,
    meta: {
      name: '开放实验室',
      login: true
    },
  },{
    path: '/course/detail/content/:type/:cur_uuid/:uuid',
    name: 'CourseTeachingContent',
    login: true,
    component: CourseTeachingContent,
    meta: {
      name: '教学内容',
      login: true
    },
  },{
    path: '/course/exercise/:cur_uuid',
    name: 'CourseExercise',
    login: true,
    component: CourseExercise,
    // meta:['全部课程', '课程详情', '试题训练']
    meta: {
      name: '试题训练',
      login: true
    },
  }]

const routes = baseRoutes.concat(userRoutes).concat(configRoutes)

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */
router.beforeEach((to, from, next) => {
  if (to.path === '/home') {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
         window.location.href = '/mobile/home'
      }
  }

  if (to.path === '/login' || to.path === '/login/') {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = '/mobile' + to.fullPath
    }
  }

  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    window.location.href = '/mobile' + to.fullPath;
  }

  if (to.meta.name) {
    document.title = `${ to.meta.name }-未来网络学堂`
    //reload 7moor js
    const s = document.createElement('script');
    s.type = 'text/JavaScript';
    s.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=a4c3d330-5dcc-11ea-b7ff-07dd5ece4b95&autoShow=true&language=ZHCN';
    document.body.appendChild(s);
  } else {
    document.title = '未来网络学堂'
  }
  console.log(to.meta.login)
  if (to.meta.login) {
    window.need_login = true;
    mustUserLogin().then(res => {
      if (res.result === -1) {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          window.location.href = '/mobile/login/?next=/mobile' + to.fullPath;
        } else {
          window.location.href = "/login/?next=" + to.fullPath;
        }
      } else {
        next()
      }
    })
  } else {
    window.need_login = false;
    next()
  }
})

export default router
