import axios from 'axios'
import axiosRouter from '@/router'
import storage from 'utils/storage'
import { showMessage } from 'api/base/message'
import { replaceAll } from "api/base/common"
const defaultTimeOut = 120000
axios.defaults.timeout = defaultTimeOut

axios.defaults.baseURL = '/api'
// 是否有请求在刷新token
window.isRefreshing = false

var CancelToken = axios.CancelToken
var cancels = []
// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    }
    // 不需要token的接口直接返回
    if (config.url.includes('/token') || config.url.includes('/openTemplate')) {
      return config
    }
    // 从localstorage中获取token
    const token = storage.localGet('accessToken')
    if (token) {
      // 请求头部设定token
      config.headers.Authorization = `Bearer ${token}`
    }
    if(Object.keys(config.params).indexOf('needCancel') >= 0){
      delete config.params.needCancel
      config.cancelToken = new CancelToken((c) => {
        cancels.push(c)
      })
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    // if (response.headers.hasOwnProperty("need_login")) {
    //   window.need_login = true;
    // }
    if (response.data.hasOwnProperty("error") && response.data.error === 'Not Login') {
      if (response.data.site_id === 0) {
        window.location.href = "/admin/login/?next=" + window.location.pathname;
      } else {
        if(storage.sessionGet('platform') == 'pc'){
          window.location.href = "/login/?next=" + window.location.pathname;
        }
        else{
          window.location.href = "/mobile/login/?next=" + window.location.pathname;
        }
      }
    }
    return response;
  },
  error => {
    // Message.error('服务器暂时无法访问，请检查网络连接或者稍后再试！')
    if (error.response && error.response.status === 429) {
      showMessage('短时间内请求数据次数过多，请稍后再试！', 'error')
    }
    // if (error.response && error.response.status === 404) {
    //   axiosRouter.push('/error/404');
    // }
    if (error.response && error.response.status === 403) {
      axiosRouter.push('/error/403');
    }
    return Promise.reject(error)
  }
)

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetch (url, params = {}) {
  if(Object.keys(params).indexOf('needCancel') >= 0){
    cancels.forEach((cancel) => {
      cancel()
    })
    cancels = []
  }
  if(params != {}){
    params = encodeSearchURI(params)
  }

  return new Promise((resolve, reject) => {
    axios.get(url, { params: params }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post (url, data = {}) {
  return new Promise((resolve, reject) => {
    let encode_data = encodeSearchURI(data);

    axios.create({
      headers: {'X-CSRFToken': getCookie('csrftoken'), 'Content-Type': 'application/x-www-form-urlencoded'},
    }).post(url, encode_data).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: {'X-CSRFToken': getCookie('csrftoken'), 'Content-Type': 'application/x-www-form-urlencoded'},
    }).put(url, data).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.create({
      headers: {'X-CSRFToken': getCookie('csrftoken'), 'Content-Type': 'application/x-www-form-urlencoded'},
    }).delete(url, data).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

export function getCookie(name) {
 var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
 if (arr = document.cookie.match(reg))
  return (arr[2]);
 else
  return null;
}

export function setCookie (c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString()) + ";path=/;";
}

export function clearCookie(name){
  setCookie(name, "", -1);
}

//清除cookie
export function clearSearchCookie(name) {
  var patt = /filter_.+.=/
  var temp
  let index = 0
  while ((temp = patt.exec(document.cookie)) != null && index < 5) {
    index ++
    let name = temp[0].split('=')[0]
    clearCookie(name)
  }
}

// 文件导出
export function downFile (url, params= {}) {
  const instance = axios.create({
    withCredentials: true, // 一定要加这个，不用设置什么请求头
  });
  return instance({
    method: 'get',
    url: `${url}`,
    params: params,
    responseType: 'blob',
    headers: {
    }
  })
}

export function postForm (url, data= {}, tm=defaultTimeOut) {
  return new Promise((resolve, reject) => {
    axios.create({
      withCredentials: true,
      headers: {'X-CSRFToken': getCookie('csrftoken'), 'Content-Type':  "multipart/form-data"}
    }).post(url, data, {timeout: tm}).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}


export function encodeSearchURI(params){

  Object.keys(params).forEach(key => {
    let words = params[key]

    if(!words){
    }
    else if(typeof words == 'string' && (key === 'query' || key === 'search_word' || key === 'keywords' || key == 'cur_query')){
      params["origin_" + key] = words;
      var arr = words.split('\\')
      var res = ''
      for(var i=0; i<arr.length; i++){
        res += arr[i]
        if(i < arr.length - 1){
          res += '\\\\'
        }
      }
      words = replaceAll(res, '%', '\\%')
      params[key] = replaceAll(words, '_', '\\\_')
    }
  })
  return params
}
