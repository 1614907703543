import axios from "axios";
import storage from 'utils/storage';

export default () => {
    // let loadingInstance;    //创建loading实例
    const vue_axios = axios.create({baseURL: 'http://127.0.0.1:8080'});     // 配置axios请求的地址
    // axios.defaults.baseURL = 'http://127.0.0.1:8080'; // 配置vue_axios请求的地址

    vue_axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
    vue_axios.defaults.crossDomain = true;
    vue_axios.defaults.withCredentials = true;  //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
    vue_axios.defaults.headers.common['Authorization'] = '';    // 设置请求头为 Authorization

    //配置请求拦截器 可以设置token信息
    vue_axios.interceptors.request.use(config => {
        // config.headers = {'X-CSRFToken': storage.localGet('csrftoken'),'Content-Type': 'application/x-www-form-urlencoded'}
        return config
    }, error => {
        // loadingInstance.close();
        return Promise.reject(error);
    });

    //配置响应拦截器
    vue_axios.interceptors.response.use(res => {
        // loadingInstance.close();
        //此处写所需代码
        if(res.data.code == '401') {
            //全局登陆过滤，当判读token失效或者没有登录时 返回登陆页面
            return false;
        }
        return Promise.resolve(res);
    }, error => {
        // loadingInstance.close();
        return Promise.reject(error);
    })
    return vue_axios;
}
